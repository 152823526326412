<template>
  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:body>
      <b-row>
        <b-col sm="3">
          <b-form-input id="search" v-model="searchText"></b-form-input>
        </b-col>
        <b-col sm="1">
          <a class="btn btn-icon btn-light-success" @click="search()">
            <i class="flaticon2-search-1"></i>
          </a>
        </b-col>
      </b-row>
      <br />
      <b-table
        hover
        bordered
        no-border-collapse
        responsive="true"
        head-row-variant="dark"
        :items.sync="items"
        :fields="fields"
        :busy.sync="isBusy"
        head-variant="dark"
        @sort-changed="onSort"
      >
        <template #cell(unduh)="data">
          <a
            :href="createDownloadUrl(data.item.laporan_filepath)"
            class="btn btn-light-primary mr-3"
            target="_blank"
          >
            <i class="flaticon2-download-1"></i>
            Download
          </a>
        </template>
      </b-table>
      <b-pagination
        v-on:input="changePage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </template>
  </KTCard>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTCard from "@/view/content/Card";
import {
  GET_ACTUAL_DATA,
  SET_ACTUAL_DATA,
  SET_ACTUAL_DATA_FILTER,
  SET_ACTUAL_DATA_LOADING, SET_ACTUAL_DATA_PAGE, SET_ACTUAL_DATA_SORT
} from "@/core/services/store/periodic-data/actual-data.module";
import { mapGetters } from "vuex";

export default {
  name: "ActualData",
  data() {
    return {
      title: this.$t("MENU.ACTUAL_DATA"),
      currentPage: 1,
      totalRows: 1,
      perPage: 1,
      isBusy: true,
      searchText: "",
      items: [],
      fields: [
        {
          key: "laporan_name",
          sortable: true,
          label: "Name"
        },
        {
          key: "laporan_bulan",
          sortable: true,
          label: "MONTH"
        },
        {
          key: "laporan_tahun",
          sortable: true,
          label: "TAHUN"
        },
        {
          key: "laporan_type",
          sortable: true,
          label: "Jenis Laporan"
        },
        {
          key: "laporan_status",
          sortable: true,
          label: "Status"
        },
        "Unduh"
      ]
    };
  },
  components: {
    KTCard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PERIODIC_DATA"), route: "actual-data" },
      { title: this.$t("MENU.ACTUAL_DATA") }
    ]);
    this.getItems();
  },
  methods: {
    search() {
      this.$store.dispatch(SET_ACTUAL_DATA_FILTER, this.searchText);
    },
    getItems() {
      this.$store.dispatch(GET_ACTUAL_DATA);
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_ACTUAL_DATA) {
          this.items = this.getActualData;
          this.currentPage = this.getActualDataCurrentPage;
          this.totalRows = this.getActualDataTotalRow;
          this.perPage = this.getActualDataPerPage;
        }

        if (mutation.type === SET_ACTUAL_DATA_LOADING) {
          this.isBusy = this.getActualDataLoadingStatus;
        }
      });
    },
    createDownloadUrl(fileName) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "api/smartmapadmin/datahamaktual/download/" +
        fileName
      );
    },
    onSort(ctx) {
      let a = {};
      a[ctx.sortBy] = ctx.sortDesc === true ? "desc" : "asc";
      this.$store.dispatch(SET_ACTUAL_DATA_SORT, a);
    },
    changePage(ctx) {
      this.$store.dispatch(SET_ACTUAL_DATA_PAGE, ctx);
    }
  },
  computed: mapGetters([
    "getActualData",
    "getActualDataLoadingStatus",
    "getActualDataCurrentPage",
    "getActualDataTotalPage",
    "getActualDataPerPage",
    "getActualDataTotalRow"
  ])
};
</script>
